<template>
	<div
		class="position-relative w-100"
		v-bind="$attrs"
	>
		<div
			v-if="isNativeSupported"
			class="w-100"
		>
			<div class="d-flex w-100">
				<div :class="range ? 'w-60' : 'w-100'">
					<label
						class="floatinglabel px-1"
						:for="startInputId"
					>{{ label }} <span v-if="required">*</span></label>
					<input 
						:id="startInputId"
						:type="inputType"
						:value="formattedStartDate"
						@input="onStartDateChange($event.target.value)"
						class="form-control"
						:required="required"
						:class="{ 'is-invalid': showError }"
						:max="maxDate"
					>
				</div>
				<div 
					v-if="range"
					class="w-60"
				>
					<label
						class="floatinglabel px-1"
						:for="startInputId"
					>{{ label }} <span v-if="required">*</span></label>
					<input 
						:id="startInputId"
						:type="inputType"
						:value="formattedEndDate"
						@input="onEndDateChange($event.target.value)"
						class="form-control me-2"
						:required="required"
						:max="maxDate"
					>
				</div>
			</div>
		</div>
		<div 
			v-else 
			ref="matDatepicker"
			class="position-relative w-100"
			v-bind="$attrs"
		>
			<div class="d-flex">
				<MatInput
					readonly
					icon="date_range"
					:model-value="formattedStartDate"
					:label="label"
					:dense="dense"
					@click="showCalendar"
					:required="required"
					aria-required="true"
					class="me-2 "
					left
				/>
				<MatInput
					v-if="range"
					readonly
					icon="date_range"
					:model-value="formattedEndDate"
					:dense="dense"
					@click="showCalendar"
					:required="required"
					aria-required="true"
					left
				/>
			</div>
		</div>
	</div>
	<MatMenu
		ref="menu"
		:anchor-to="anchor"
		class="mdc-datepicker bulma"
	>
		<input ref="bulmaCalendarInput">
	</MatMenu>
</template>
<script>
import MatInput from "./mat-input.vue";
import MatMenu from "./mat-menu.vue";
const bulmaCalendar = () => import("bulma-calendar/src/js");

export default {
	components: { MatInput, MatMenu },
	props: {
		type: {
			type: String,
			default: "datetime",
		},
		monthPicker: {
			type: Boolean,
			default: false,
		},
		range: {
			type: Boolean,
			default: false,
		},
		modelValue: {
			type: [String, Array, null],
			default: null,
		},
		label: {
			type: String,
			default: "",
		},
		dense: Boolean,
		required: Boolean,
		
		options: {
			type: Object,
			default() {
				return {
					lang: "fr",
					showFooter: false,
					icons: {
						next: `<span class="material-icons">navigate_next</span>`,
						previous: `<span class="material-icons">navigate_before</span>`,
					},
				};
			},
		},
		maxDate:Date
	},
emits: ['update:modelValue'],
	data() {
		return {
			date: [null, null],
			anchor: null,
			isNativeSupported: true,
			showError: false,
		};
	},
	computed: {
		inputType() {
			return this.monthPicker ? "month" : "date";
		},
		formattedStartDate() {

			if(this.isNativeSupported ) return  this.date[0] ? this.formatValue(this.date[0]) : ""
			const format = this.monthPicker ? 'MM/YYYY' : 'DD/MM/YYYY';			
			return this.date[0] ? this.$filter.format(this.date[0], format) : format;

		},
		formattedEndDate() {
			if (this.isNativeSupported) return this.range && this.date[1] ? this.formatValue(this.date[1]) : "";
			const format = this.monthPicker ? 'MM/YYYY' : 'DD/MM/YYYY';
			return this.date[1] ? this.$filter.format(this.date[1], format) : format;
		},
	},
	watch: {
		date() {
			this.$emit("update:modelValue", Array.isArray(this.modelValue) ? this.date : this.date[0]);
		},
		modelValue: {
			handler() {
				if (!this.$utils.equals(this.date, this.modelValue))
					Array.isArray(this.modelValue) ? this.date = this.modelValue : this.date[0] = this.modelValue;
			},
			immediate: true,
		}

	},
	async mounted() {
		this.isNativeSupported = this.isInputTypeSupported(this.monthPicker ? "month" : "date");		
		if (!this.isNativeSupported) {
			this.calendar = (await bulmaCalendar()).default
			this.initCalendar();
}
	},

	methods: {
		isInputTypeSupported(type) {
			if (!window.inputTypes) window.inputTypes = {};
			if (window.inputTypes[type] !== undefined) return window.inputTypes[type];
			const test = document.createElement('input');
			test.setAttribute('type', type);
			const supported = test.type == type;
			test.remove();
			window.inputTypes[type] = supported;
			return supported;
		},
		formatValue(value) {			
			return this.monthPicker ? value.slice(0, 7) : value;
		},
		onStartDateChange(value) {
			this.date[0] = value;
			this.emitValue();
		},
		onEndDateChange(value) {
			if (this.range) {
				this.date[1] = value;
				this.emitValue();
			}
		},
		emitValue() {
			this.$emit("update:modelValue", this.range ? [...this.date] : this.date[0]);
		},

		showCalendar() {
			this.$refs.menu.open();
			if (this.monthPicker) this.openMonthPicker() 
		},
		initCalendar() {
			this.anchor = this.$refs.matDatepicker;
			if (this.range) {
				if (this.modelValue instanceof Array) {
					this.date = this.modelValue;
				}
			} else {
				if (this.modelValue) {
					this.date[0] = Array.isArray(this.modelValue)
						? this.modelValue[0]
						: this.modelValue;
				}
			}
			this.date[0] && (this.date[0] = this.$moment(this.date[0]).toDate())
			this.date[1] && (this.date[1] = this.$moment(this.date[1]).toDate())
			const calendar = this.calendar.attach(
				this.$refs.bulmaCalendarInput,
				this.calendarOptions()
			);
			this.calendar = calendar[0];
			this.calendar.on("select", (e) => {
				if (this.range) {
					this.date = [e.data.startDate, e.data.endDate];
				} else {
					this.date = [e.data.startDate];
				}

				this.$refs.menu.hide();
			});

			this.calendar.on("clear", () => {
				if (this.range) {
					this.date = [null, null];
				} else {
					this.date = [null];
				}
			});
		},
		calendarOptions() {
			return {
				...this.options,
				// showHeader: false,
				showClearButton: false,
				showValidateButton: false,
				showTodayButton: false,
				displayMode: "inline",
				isRange: this.range,
				startDate: this.date[0],
				startTime: this.date[0],
				endDate: this.date[1],
				endTime: this.date[1],
			};
		},
		openMonthPicker() {
			if (this.monthPicker) {
				const yearPickerElement = this.calendar.datePicker._ui.navigation.year;
				yearPickerElement.click()
				const years = this.calendar.datePicker._ui.body.years.querySelectorAll('.datepicker-year') || [];
				years.forEach((year) => {
					year.addEventListener("click", (e) => this.getYear(e), { once: true });
				});
			}
		},
		getYear(e) {
			this.selectedYear = e.currentTarget.dataset.year;
			this.calendar.datePicker._ui.navigation.month.click();
			const month = this.calendar.datePicker._ui.body.months.querySelectorAll('.datepicker-month') || [];
			month.forEach((month) => {
				month.addEventListener("click", (e) => {
					this.getMonth(e)
				}, { once: true });
			});
		},
		getMonth(e) {
			//const selectedYear = e.currentTarget.dataset.year; // Get the year from the clicked element
			const selectedMonth = e.currentTarget.dataset.month; // Get the month from the clicked element
			// Update this.date with the selected year and month
			this.$refs.menu.hide();
			if (this.selectedYear && selectedMonth) {
				const formattedDate = `${this.selectedYear}-${selectedMonth}-01`;
				this.date = [formattedDate];
			}

		},
	},
};

</script>
<style lang="scss" >
.floatinglabel {
    position: absolute;
    bottom: 75%;
    color:  rgba(0, 0, 0, 0.6);
    transform: translateY(0.5px) scale(0.75) !important;
    font-size: 1rem;
	z-index: 100;
	background-color: white;
}

.form-control {
	height: 40px !important;
	padding: 0.375rem 0.75rem;
	border-color: rgba(0, 0, 0, 0.35);
	transition: border-color 0.2s ease-in-out;
}

.form-control:focus, 
.form-control:hover  {
	border-color: var(--primary-color);
	outline: none;
	box-shadow: none;
}
.mdc-datepicker {
	z-index: 1000000;

	*,
	*::after,
	*::before {
		box-sizing: border-box;
	}

	.timepicker {
		display: none;
	}

	.datetimepicker.is-primary .datetimepicker-header .datetimepicker-selection-day {
		color: var(--primary-color);
		min-width: fit-content;
	}

	.datetimepicker.is-primary .datepicker-body .datepicker-dates .datepicker-days .datepicker-date .date-item.is-active {
		background: var(--primary-color);
		border-color: var(--primary-color);
	}

	.datetimepicker.is-primary .datepicker-body .datepicker-dates .datepicker-days .datepicker-date .date-item.is-today {
		border-color: var(--primary-color);
		color: var(--primary-color);
	}

	.datetimepicker.is-primary .datepicker-body .datepicker-dates .datepicker-days .datepicker-date .date-item:hover {
		border-color: var(--primary-color);
	}

	.datetimepicker.is-primary .datepicker-body .datepicker-dates .datepicker-days .datepicker-date.datepicker-range {
		background: var(--primary-color);
	}

	.datetimepicker.is-primary .datepicker-body .datepicker-dates .datepicker-days .datepicker-date.datepicker-range .date-item {
		background-color: var(--primary-color);
		color: #fff;
	}

	.datetimepicker.is-primary .datepicker-nav {
		background: var(--primary-color);
		color: #fff;
	}

	.has-text-success {
		color: var(--primary-color) !important;
	}

	.has-text-warning {
		color: var(--primary-color-dark) !important;
	}

	.button.is-text:active,
	.button.is-text.is-active {
		background-color: unset;
		color: unset;
	}

	.datetimepicker .datetimepicker-header {
		display: none;
	}

	.button.is-text {
		text-decoration: none !important;
		color: white
	}
}

</style>
