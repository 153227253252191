<template lang="">
	<ParentForm
		v-if="personalInfo"
		:title="$route.name == 'userDetail' ? '': $siteconf.cooptation ? $t('coopInfoPer') : $t('infoGen.formText')"
		headerClass="pb-2"
		:disabled="isDisabled"
		@update="editform($event)"
	>
		<span>
			<span
				v-if="$route.name == 'userDetail'"
				class="pb-3"
			>

				<span
					class="text-success"
					v-if="checkAccount?.done"
				> {{ $t(`vCompte`) }}</span>
				<span
					class=" sectionTitle "
					v-else
				>
					<MatButton
						v-if="checkAccount?.retry && !alreadysend && !$toMove.isSendLinkClicked"
						class="conditionLink "
						:label="$t(`vrCompte`)"
						outlined
						@click="verifyAccount"
					/>
					<MatButton
						v-else
						:label="$t(`avCompte`)"
						class="conditionLink "
						disabled
						outlined
					/>
				</span>
			</span>

			<div class="d-flex flex-wrap mb-4">
				<div class="picture rounded-border me-4 flex-center position-relative bg d-none">
					<span
						class="material-icons mini h3 txt-disabled"
						v-if="!personalInfo.picture"
					>
						account_circle
					</span>
					<img
						class="img"
						v-else
						:src="personalInfo.picture"
					>
					<input
						class="d-none"
						type="file"
						ref="resumeInput"
						accept=".jpeg,.jpg,.png"
						@change="addPicture"
					>
					<MatFab
						class="pic-add"
						mini
						icon="add"
						@click="$refs.resumeInput.click()"
					/>
				</div>
		
				<div class="flex-max flex-wrap ">
					<div
						class="d-flex my-3"
						v-if="$route.name == 'userDetail'"
					>
						<MatInput
							disabled
							class="me-3"
							dense
							v-model="personalInfo.email"
							:label="$t('addMail')"
						/>
						<MatInput
							:class="{'pe-none': isDisabled}"
							:required="$siteconf.contact_choice == 'both' || $siteconf.contact_choice == 'phone'"
							:label="$t('mail-phone.phone')"
							dense
							v-model="personalInfo.phone"
							class="pe-3"
						/>
					</div>
					<div
						class="d-flex "
					>
						<MatInput
							class="me-3"
							dense
							v-model="personalInfo.firstName"
							:label="$t('infoGen.prenom')"
							required="true"
							:class="{'pe-none': isDisabled}"
						/>
						<MatInput
							dense
							v-model="personalInfo.lastName"
							class="pe-3"
							:label="$t('infoGen.nom')"
							required="true"
							:class="{'pe-none': isDisabled}"
						/>
					</div>
					<div
						class="d-flex mt-3"
						v-if="$siteconf.accounts && ['both', 'phone'].includes($siteconf.contact_choice) && $route.name != 'userDetail'"
					>
						<MatInput
							dense
							v-model="personalInfo.phone"
							:required="$siteconf.contact_choice == 'both' || $siteconf.contact_choice == 'phone'"
							:label="$t('mail-phone.phone')"
							type="tel"
							name="phone"
							:class="{'pe-none': isDisabled}"
						/>
					</div>
					<div class="d-flex mt-3 ">
						<MatDatepicker
							dense
							v-model="personalInfo.birthday"
							:label="$t('infoGen.naissance')"
							:required="$siteconf?.mandatory_fields?.birthday"
							:class="{'pe-none': isDisabled}"
							:maxDate="$siteconf?.mandatory_fields?.birthday ? maxDate:null"
						/>
						<MatSelect
							v-model="personalInfo.gender"
							class="ms-3"
							dense
							:label="$t('infoGen.gender')"
							:required="$siteconf?.mandatory_fields?.gender"
							:class="{'pe-none': isDisabled}"
						>
							<MatListItem
								v-for="val in genders"
								:key="val.key"
								:value="val.key"
								:label="$t('genders.'+val.key)"
								:class="{'pe-none': isDisabled}"
							/>
						</MatSelect>
					</div>
				</div>
			</div>
			<div class="w-100 mb-4">
				<div class="d-flex">
					<MatSelect
						v-model="personalInfo.country"
						class="me-3"
						dense
						:label="$t('infoGen.pays')"
						positionBottom
						:required="$siteconf?.mandatory_fields?.country"
						:class="{'pe-none': isDisabled}"
					>
						<MatListItem
							v-for="val in countries"
							:key="val.key"
							:value="val.key"
							:label="$t('countries.'+val.key)"
						/>
					</MatSelect>
					<MatInput
						v-model="personalInfo.cp"
						dense
						:label="$t('infoGen.cp')"
						:type="cp.type || 'number'"
						:minlength="cp.minLen"
						:maxlength="cp.maxLen"
						:Pattern="cp.rx"
						class="me-3"
						:required="$siteconf?.mandatory_fields?.zipcode"
						:class="{'pe-none': isDisabled}"
					/>
					<MatInput
						v-model="personalInfo.city"
						dense
						class=""
						:label="$t('infoGen.ville')"
						:required="$siteconf?.mandatory_fields?.city"
						:class="{'pe-none': isDisabled}"
					/>
				</div>
			</div>
			<div class="w-100 mb-4">
				<div class="d-flex ">
					<MatInput
						v-model="personalInfo.address"
						dense
						:label="$t('infoGen.adress')"
						:required="$siteconf?.mandatory_fields?.address"
						:class="{'pe-none': isDisabled}"
					/>
				</div>
			</div>
			<span
				class="d-flex flex-vertical-center "
				v-if="this.$siteconf.cooptation && this.$siteconf.cooptation_consent_required"
			>
				<MatCheckbox
					v-model="accept"
					id="acceptTerms"
				/>
				<label for="acceptTerms">
					{{ $t('accept') }} <a
						class="body1 text-center under"
						:href="$siteconf.policy_link || '/privacy'"
						target="_blank"
					>{{ $t('cond') }}</a>
				</label>
			</span>
			<div
				class="pb-4 d-flex flex-column "
				:class="{'d-none': $route.params.step=='validation'|| $route.name=='userDetail' }"
			> 
				<div class="d-flex flex-row justify-content-center">
					<MatButton
						outlined
						v-if="$route.params.config!='manual'"
						:label="$t('return')"
						@click="onPrevClick"
						class="me-4"
					/>
					<MatButton
						v-if="$route.params.step!='validation' && form"
						:disabled="invalidForm || (this.$siteconf.cooptation && this.$siteconf.cooptation_consent_required&& !accept)"
						raised
						:label="$t('suiv')"
						@click="onNextClick"
					/>
					<MatButton
						v-if="!form"
						:disabled="invalidForm || clicked"
						raised
						:label="labelBtn"
						@click="nextStep"
					/>
				</div>
			</div>
		</span>
	
		<span v-if="isDisabled && $route.name=='userDetail'" />
	</ParentForm>
</template>
<script>

import { ROUTES_NAME } from "@/router"
const verifphone = /^[+\d]\d{5,}$/

export default {
	components: {
	},
	emits: ['updateCandidData', 'updateUser'],
	data() {
		return {
			personalInfo: {},
			show: false,
			countries: Countries,
			genders: Genders,
			cp: {
				type: null
			},
			clicked: false,
			invalidForm: false,
			isDisabled: true,
			birthday: null,
			alreadysend: false,
			firstData: null,
			click: false,
			accept:false
		}
	},
	props: {
		data: {
			type: Object,
			default: () => null
		},
		title: {
			type: String,
			default: "Veuillez saisir vos informations personelles"
		},
		onNextClick: {
			type: Function,
			default: () => () => alert("next")
		},
		onPrevClick: {
			type: Function,
			default: () => () => alert("prev")
		},
		form: {
			type: Boolean,
			default: () => true
		},
		checkAccount: {
			type: Object,
			default: () => null
		}
	},
	watch: {
		data() {
			this.personalInfo = this.data;
			if (this.personalInfo.firstName && this.personalInfo.lastName) {
				this.show = true
			}
		},
		modelValue: {
			handler() {
				this.value = this.modelValue;
			},
			immediate: true,
		},
		personalInfo: {
			handler() {
				if (this.$route.name == ROUTES_NAME.POST) {
					this.$userData(null, 'contact', this.personalInfo);
					this.updateCp();
					this.invalid();
				}
			},
			deep: true,
		},
		$route: {
			handler() {
				if (this.$route.name == ROUTES_NAME.POST) this.isDisabled = false
			}, immediate: true
		},
	},
	async mounted() {
		this.personalInfo = this.$userData.load('contact', this.data);
		document.body.scrollTop = 0;
		this.firstData = JSON.parse(JSON.stringify(this.personalInfo));
	},

methods: {
	addPicture() {
		let picture = this.$refs.resumeInput.files[0]
		var reader = new FileReader()
		reader.readAsDataURL(picture)
		reader.onload = () => {
			this.personalInfo.picture = reader.result
		};
	},
	updateCp() {
		const t = this;
		const country = Countries.find(c => c.key === t.personalInfo.country);
		if (country && country.cp) this.cp = country.cp;
		else this.cp = { type: 'text', minLen: 2, maxLen: 10, rx: ".{2,10}" };
	},

	nextStep() {
		this.clicked = true;
		this.onNextClick();
	},
	invalid() {
		if (!this.personalInfo?.firstName) { this.invalidForm = true }
		else if (!this.personalInfo?.lastName) { this.invalidForm = true }
		else if (!this.personalInfo?.birthday && this.$siteconf?.mandatory_fields?.birthday) { this.invalidForm = true }
		else if (!this.personalInfo?.gender && this.$siteconf?.mandatory_fields?.gender) { this.invalidForm = true }
		else if (!this.personalInfo?.city && this.$siteconf?.mandatory_fields?.city) { this.invalidForm = true }
		else if (!this.personalInfo?.country && this.$siteconf?.mandatory_fields?.country) { this.invalidForm = true }
		else if (!this.personalInfo?.address && this.$siteconf?.mandatory_fields?.address) { this.invalidForm = true }
		else if (!this.personalInfo?.cp && this.$siteconf?.mandatory_fields?.zipcode) { this.invalidForm = true }
		else if (this.personalInfo?.cp && this.cp?.type) {
			if (this.cp?.type === 'text') {
				this.invalidForm = (typeof this.personalInfo.cp !== 'string' || (this.cp.minLen && this.cp.minLen > this.personalInfo.cp.length)
					|| (this.cp.maxLen && this.cp.maxLen < this.personalInfo.cp.length)
					|| !(this.cp.rx && new RegExp(this.cp.rx).test(this.personalInfo.cp)))
			} else if (this.cp?.type === 'number') {
				this.invalidForm = !(this.cp.rx && new RegExp(this.cp.rx).test(this.personalInfo.cp))
			}
		}
		else if ((this.$siteconf.contact_choice == 'both' || this.$siteconf.contact_choice == 'phone') &&
			((this.personalInfo.phone && !verifphone.exec(this.personalInfo.phone)) || !this.personalInfo.phone)) { this.invalidForm = true }
		else this.invalidForm = false

	},

	editform(type) {
		//edit: show edit form 
		if (type == 'edit') { this.isDisabled = !this.isDisabled }

		else if (type == 'cancelUpdate') {
			this.isDisabled = true
			this.personalInfo = this.firstData;

			this.$userData(null, 'contact', this.personalInfo);

			this.firstData = JSON.parse(JSON.stringify(this.personalInfo));
		}
		else if (type == 'update') {
			let obj = JSON.parse(JSON.stringify(this.personalInfo))
			obj = this.prepareCandidateInfo(obj)
			this.$emit("updateUser", { "contact": obj });
			let user = {}
			user.personal = this.personalInfo
			this.$utils.updateUserStorage(user)
			this.isDisabled = true
			this.firstData = JSON.parse(JSON.stringify(this.formdata));
		}
	},

	prepareCandidateInfo(obj) {
		const getYearMonthDay = function (date) {
			return { year: date.getFullYear(), month: date.getMonth() , day: date.getDate() }
		}
		const candidate = {};
		if (obj.firstName) candidate.firstname = obj.firstName;
		if (obj.lastName) candidate.lastname = obj.lastName;
		if (obj.address || obj.city || obj.country || obj.cp) {
			candidate.location = this.location(obj);
		}
		if (obj.gender) candidate.gender = obj.gender || "";
		if (obj.birthday) candidate.birthday = obj.birthday ? getYearMonthDay(new Date(obj.birthday)) : null;
		if (obj.picture) candidate.photo = obj.picture;
		if (obj.phone) candidate.phone = obj.phone;
		return candidate;
	},

	location(obj) {
		let loc = {}
		if (obj.address) loc.address = obj.address
		if (obj.city) loc.city = obj.city
		if (obj.country) loc.country = obj.country
		if (obj.cp) loc.cp = obj.cp
		return loc
	},
		async verifyAccount() {
			this.$toMove.isSendLinkClicked =true

		try {
			await this.$service.appservice.retryAccount()
			this.alreadysend = true
		} catch (error) {
			console.log("error while sending the mail");
			//this.alreadysend=true
		}
	},

},
computed: {
	labelBtn() {
		if (this.clicked) return this.$t('waiting');
		else return this.$t('soumettreCandi')
	},
	isButtonDisabled() {
		// Disable the button if it has been clicked or if checkAccount.retry is false
		//return this.$store.ge(true);
		return false
	},
	maxDate() {
		const today = new Date();
		const maxDate = new Date();

		// Subtract 15 years from today
		maxDate.setFullYear(today.getFullYear() - 15);
		return maxDate.toISOString().slice(0, 10);
	}
},
}

const Genders = [
	{ "key": "male" },
	{ "key": "female" },
	{ "key": "undisclosed" }
]

const Countries = [
	{ "key": "france", cp: { rx: "^\\d{5}$", type: 'text', minLen: 5, maxLen: 5 } },
	{ "key": "afghanistan" },
	{ "key": "south africa" },
	{ "key": "albania" },
	{ "key": "algeria" },
	{ "key": "germany" },
	{ "key": "andorra" },
	{ "key": "angola" },
	{ "key": "antigua and barbuda" },
	{ "key": "saudi arabia" },
	{ "key": "argentina" },
	{ "key": "armenia" },
	{ "key": "australia" },
	{ "key": "austria" },
	{ "key": "azerbaijan" },
	{ "key": "bahamas" },
	{ "key": "bahrain" },
	{ "key": "bangladesh" },
	{ "key": "belgium" },
	{ "key": "belize" },
	{ "key": "benin" },
	{ "key": "bhutan" },
	{ "key": "belarus" },
	{ "key": "burma (myanmar)" },
	{ "key": "bolivia" },
	{ "key": "bosnia and herzegovina" },
	{ "key": "botswana" },
	{ "key": "brazil" },
	{ "key": "brunei" },
	{ "key": "bulgaria" },
	{ "key": "burkina" },
	{ "key": "burundi" },
	{ "key": "cambodia" },
	{ "key": "cameroon" },
	{ "key": "canada" },
	{ "key": "cape verde" },
	{ "key": "chile" },
	{ "key": "china" },
	{ "key": "cyprus" },
	{ "key": "colombia" },
	{ "key": "comoros" },
	{ "key": "congo" },
	{ "key": "korea, north" },
	{ "key": "korea, south" },
	{ "key": "costa rica" },
	{ "key": "ivory coast" },
	{ "key": "croatia" },
	{ "key": "cuba" },
	{ "key": "denmark" },
	{ "key": "djibouti" },
	{ "key": "dominica" },
	{ "key": "egypt" },
	{ "key": "el salvador" },
	{ "key": "united arab emirates" },
	{ "key": "ecuador" },
	{ "key": "eritrea" },
	{ "key": "spain" },
	{ "key": "estonia" },
	{ "key": "ethiopia" },
	{ "key": "russian federation" },
	{ "key": "fiji" },
	{ "key": "finland" },
	{ "key": "gabon" },
	{ "key": "gambia" },
	{ "key": "georgia" },
	{ "key": "ghana" },
	{ "key": "greece" },
	{ "key": "grenada" },
	{ "key": "guatemala" },
	{ "key": "guinea" },
	{ "key": "equatorial guinea" },
	{ "key": "guinea-bissau" },
	{ "key": "guyana" },
	{ "key": "haiti" },
	{ "key": "honduras" },
	{ "key": "hungary" },
	{ "key": "mauritius" },
	{ "key": "marshall islands" },
	{ "key": "solomon islands" },
	{ "key": "samoa" },
	{ "key": "india" },
	{ "key": "indonesia" },
	{ "key": "iraq" },
	{ "key": "iran" },
	{ "key": "ireland" },
	{ "key": "iceland" },
	{ "key": "israel" },
	{ "key": "italy" },
	{ "key": "jamaica" },
	{ "key": "japan" },
	{ "key": "jordan" },
	{ "key": "kazakhstan" },
	{ "key": "kenya" },
	{ "key": "kuwait" },
	{ "key": "kyrgyzstan" },
	{ "key": "barbados" },
	{ "key": "laos" },
	{ "key": "united states" },
	{ "key": "lesotho" },
	{ "key": "latvia" },
	{ "key": "lebanon" },
	{ "key": "liberia" },
	{ "key": "libya" },
	{ "key": "liechtenstein" },
	{ "key": "lithuania" },
	{ "key": "luxembourg" },
	{ "key": "macedonia" },
	{ "key": "madagascar" },
	{ "key": "malawi" },
	{ "key": "malaysia" },
	{ "key": "maldives" },
	{ "key": "mali" },
	{ "key": "malta" },
	{ "key": "morocco" },
	{ "key": "mauritania" },
	{ "key": "mexico" },
	{ "key": "micronesia" },
	{ "key": "moldova" },
	{ "key": "monaco" },
	{ "key": "mongolia" },
	{ "key": "montenegro" },
	{ "key": "mozambique" },
	{ "key": "namibia" },
	{ "key": "nauru" },
	{ "key": "nepal" },
	{ "key": "nicaragua" },
	{ "key": "niger" },
	{ "key": "nigeria" },
	{ "key": "norway" },
	{ "key": "new zealand" },
	{ "key": "oman" },
	{ "key": "uganda" },
	{ "key": "uzbekistan" },
	{ "key": "pakistan" },
	{ "key": "palau" },
	{ "key": "palestine" },
	{ "key": "panama" },
	{ "key": "papua new guinea" },
	{ "key": "paraguay" },
	{ "key": "netherlands" },
	{ "key": "peru" },
	{ "key": "philippines" },
	{ "key": "poland" },
	{ "key": "portugal" },
	{ "key": "qatar" },
	{ "key": "central african republic" },
	{ "key": "kiribati" },
	{ "key": "congo, democratic republic of" },
	{ "key": "dominican republic" },
	{ "key": "czech republic" },
	{ "key": "romania" },
	{ "key": "united kingdom" },
	{ "key": "rwanda" },
	{ "key": "saint vincent and the grenadines" },
	{ "key": "saint kitts and nevis" },
	{ "key": "san marino" },
	{ "key": "saint lucia" },
	{ "key": "sao tome and principe" },
	{ "key": "senegal" },
	{ "key": "serbia" },
	{ "key": "seychelles" },
	{ "key": "sierra leone" },
	{ "key": "singapore" },
	{ "key": "slovakia" },
	{ "key": "slovenia" },
	{ "key": "somalia" },
	{ "key": "sudan" },
	{ "key": "south sudan" },
	{ "key": "sri lanka" },
	{ "key": "sweden" },
	{ "key": "switzerland" },
	{ "key": "suriname" },
	{ "key": "swaziland" },
	{ "key": "syria" },
	{ "key": "tajikistan" },
	{ "key": "tanzania" },
	{ "key": "chad" },
	{ "key": "thailand" },
	{ "key": "east timor" },
	{ "key": "togo" },
	{ "key": "tonga" },
	{ "key": "trinidad and tobago" },
	{ "key": "tunisia" },
	{ "key": "turkmenistan" },
	{ "key": "turkey" },
	{ "key": "tuvalu" },
	{ "key": "ukraine" },
	{ "key": "uruguay" },
	{ "key": "vanuatu" },
	{ "key": "vatican city" },
	{ "key": "venezuela" },
	{ "key": "vietnam" },
	{ "key": "yemen" },
	{ "key": "zambia" },
	{ "key": "zimbabwe" }
]
</script>
<style lang="scss" scoped>
.picture {
	width: 85px;

	.pic-add {
		position: absolute;
		right: -20px;
		bottom: 0;
	}
}

.bg {
	background: rgba(0, 0, 0, 0.08);
	border-radius: 10px !important;
}

.mini {
	font-size: 45px !important;
}

.img {
	width: -webkit-fill-available;
	height: -webkit-fill-available;
	border-radius: 10px !important;
}
</style>
