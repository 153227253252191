/* eslint-disable */
module.exports = function ($t,$route) {
	return {
		BAD_GATEWAY: {
			code: 502, location: "/bad_gateway", name: "bad_gateway",
			text: "Sorry, there was a technical error, please try again later"
		},
		NOT_FOUND: {
			code: 404, location: "/not_found", name: "not_found",
			text: "Page not found, sorry"
		},
		NOT_AUTHORIZED: {
			code: 401, location: "/not_authorized", name: "not_authorized",
			text: "You are not authorized"
		},
		SERVER_ERROR: {
			code: 500, location: "/server_error", name: "server_error",
			text: "Sorry, there was a technical error, please try again later"
		},
		EXPIRED: {
			code:  $route.params.config=="form"? 400 : 500 , location: "/expired", name: "expired",
			text: $route.params.config=="form"? $t("expiredForm")  :$t("validationExpired")
		},
		INVALID: {
			code: $route.params.config=="form"? 400 : 500 , location: "/invalid-link", name: "invalid",
			text: $route.params.config=="form"? $t("invalidForm"): $t("validationInvalid")
		},
	}

}
